export default {
  code: 200,
  countryMaxDates: [
    {
      countryName:'中国大陆',
      countryKey:'CN',
      ingSrc:'/images/national/zgdl.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'中国香港',
      countryKey:'HK',
      ingSrc:'/images/national/zgxg.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'印度尼西亚',
      countryKey:'ID',
      ingSrc:'/images/national/ydnxy.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'印度',
      countryKey:'IN',
      ingSrc:'/images/national/yd.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'日本',
      countryKey:'JP',
      ingSrc:'/images/national/rb.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'土耳其',
      countryKey:'TR',
      ingSrc:'/images/national/trq.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'韩国',
      countryKey:'KR',
      ingSrc:'/images/national/hg.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'中国澳门',
      countryKey:'MO',
      ingSrc:'/images/national/am.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'马来西亚',
      countryKey:'MY',
      ingSrc:'/images/national/mlxy.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'菲律宾',
      countryKey:'PH',
      ingSrc:'/images/national/flb.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'新加坡',
      countryKey:'SG',
      ingSrc:'/images/national/xjp.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'中国台湾',
      countryKey:'TW',
      ingSrc:'/images/national/zgtw.png',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'泰国',
      countryKey:'TH',
      ingSrc:'/images/national/tgs.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'阿拉伯联合酋长国',
      countryKey:'AE',
      ingSrc:'/images/national/alq.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'奥地利',
      countryKey:'AT',
      ingSrc:'/images/national/adl.JPG',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'瑞士',
      countryKey:'CH',
      ingSrc:'/images/national/rs.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'德国',
      countryKey:'DE',
      ingSrc:'/images/national/dg.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'丹麦',
      countryKey:'DK',
      ingSrc:'/images/national/dm.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'挪威',
      countryKey: 'NO',
      ingSrc:'/images/national/nw.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'西班牙',
      countryKey:'ES',
      ingSrc:'/images/national/xby.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'欧盟',
      countryKey:'EU',
      ingSrc:'/images/national/om.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'芬兰',
      countryKey:'FI',
      ingSrc:'/images/national/fl.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'法国',
      countryKey:'FR',
      ingSrc:'/images/national/fg.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'英国',
      countryKey:'GB',
      ingSrc:'/images/national/yg.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'葡萄牙',
      countryKey:'PT',
      ingSrc:'/images/national/pty.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'希腊',
      countryKey:'GR',
      ingSrc:'/images/national/xl.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'匈牙利',
      countryKey:'HU',
      ingSrc:'/images/national/xyl.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'爱尔兰',
      countryKey:'IE',
      ingSrc:'/images/national/ael.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'冰岛',
      countryKey:'IS',
      ingSrc:'/images/national/bd.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'意大利',
      countryKey:'IT',
      ingSrc:'/images/national/ydl.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'瑞典',
      countryKey:'SE',
      ingSrc:'/images/national/rd.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'俄罗斯',
      countryKey:'RU',
      ingSrc:'/images/national/elss.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'比荷卢经济联盟',
      countryKey:'BX',
      ingSrc:'/images/national/bhl.png',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'加拿大',
      countryKey:'CA',
      ingSrc:'/images/national/jnd.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'墨西哥',
      countryKey:'MX',
      ingSrc:'/images/national/mxg.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'美国',
      countryKey:'US',
      ingSrc:'/images/national/mg.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'阿根廷',
      countryKey:'AR',
      ingSrc:'/images/national/agt.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'巴西',
      countryKey:'BR',
      ingSrc:'/images/national/bx.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'智利',
      countryKey:'CL',
      ingSrc:'/images/national/zl.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'南非',
      countryKey:'ZA',
      ingSrc:'/images/national/nf.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'澳大利亚',
      countryKey:'AU',
      ingSrc:'/images/national/adly.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'新西兰',
      countryKey:'NZ',
      ingSrc:'/images/national/xxl.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    },
    {
      countryName:'世界知识产权组织国际局',
      countryKey:'WO',
      ingSrc:'/images/national/zscq.jpg',
      isGraphQuerySelect: "1",
      disabled:false
    }]
}
