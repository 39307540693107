import { Loading, Message } from 'element-ui'
import { deepClone } from '../../../public/js/utils'

const statusObj = {
        '1': '已注册',
        '2': '无效',
        '3': '公告中',
        '4': '驳回复审审查中',
        '5': '异议审查中',
        '6': '注册申请审查中',
        '7': '撤销/无效宣告申请审查中',
        '8': '其他情形',
        '9': '无效',
        '10': '已注册',
        '11': '公告中',
        '12': '审查中'
    }
    /* 组件显隐 */
export let isShowFn = function(current, flag) {
        for (let key in this.queryItemsShow.isShow) {
            if (key == current) {
                this.queryItemsShow.isShow[key] = flag == 'switch' ? !this.queryItemsShow.isShow[key] : true
            } else {
                this.queryItemsShow.isShow[key] = false
            }
        }
        this.queryDataFn()
    }
    /* ai查询进度 */
export let progressBarFn = function() {
    this.queryItemsShow.progressNum = 0
    this.queryItemsShow.interval = setInterval(() => {
        if (this.queryItemsShow.progressNum < 99) {
            this.queryItemsShow.progressNum += 1
        } else {}
    }, 60)
}

/**
 * 分页(含高级,快速)
 * */
export let pageNoChange = function(canshu) {
    isShowFn.bind(this)("progressBar");
    this.queryItemsShowFn({
        noDataBol: false,
    });
    this.isFirstQueryFn(false);
    switch (this.othersItem.queryRecently) {
        case "fastTextQuery":
            queryTmInfoTextByMulCountryFromDB.bind(this)();
            break;
        case "fastGraphQuery":
            queryTmInfoGraphByMulCountryFromDB.bind(this)();
            break;
        case "highGradeTextQuery":
            queryTmInfoTextByMulCountryFromDB.bind(this)();
            break;
        case "highGradeGraphQuery":
            queryTmInfoGraphByMulCountryFromDB.bind(this)();
            break;
    }
};

/**
 *文字生成图片
 * */
export let createFn = function(word) {
    //return;//191010,孟巍群内要求,可搜索"3、没有上传图片不显示图片"
    let serchWord = word || this.queryData.fastWordConfirm
    if (serchWord == '') {
        return
    }
    let jishu = this.getBLen(serchWord) * 0.6
    this.generate({
            font: serchWord,
            fontpx: 800 / jishu
        })
        .then((data) => {
            this.queryDataFn({
                imgLocalUrl: this.updataUrl(data.imgBase64),
                // imgLineUrl: this.updataUrl(data.imgBase64),
            })
        })
        .catch((error) => {
            console.log(error)
        })
};
//多国文字查询
export let queryTmInfoTextByMulCountryFromDB = function() {
    let loading = Loading.service({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.3)',
        customClass: 'loading_interceptors'
    });
    this.queryItemsShowFn({
        noDataBol: false,
    });

    let appno = "";
    let regno = "";
    let applicantEn = "";
    let applicantCh = "";
    let trademarkList = "";
    if (this.queryData.applicationNumber != '') {
        //空格不作处理。有申请号包含空格的数据，如丹麦“VA 2008 00214”
        appno = this.queryData.applicationNumber.replace(/[.]+/g, '').replace(/[，,；;]+/g, ';');
    }
    if (this.queryData.registerNumber) {
        //空格不作处理。有申请号包含空格的数据，如丹麦“VA 2008 00214”
        regno = this.queryData.registerNumber.replace(/[.]+/g, '').replace(/[，,；;]+/g, ';');
    }
    let status = "";
    if (this.queryData.statusList[this.queryData.country + '_list']) {
        this.queryData.statusList[this.queryData.country + '_list'].map((item) => {
            if (item.isQuery) {
                status += item.index + ";";
            }
        });
    }

    let asideFormData = deepClone(this.$store.state.query.asideForm);
    for (let key in asideFormData) {
        let values = Object.values(asideFormData)
        if (values.some(i => (i || []).length)) {
            asideFormData[key] = asideFormData[key].join(';')
        } else {
            asideFormData = ''
        }
    }
    let secondQuery = asideFormData || '';

    // let sendCountry = deepClone(this.queryData.country)
    // sendCountry = sendCountry.length == 0 ? 'CN' : sendCountry.join(';')

    let sendData = {
        applicantQueryWay: this.queryData.applicantQueryWay,
        pageSize: this.pageSize_,
        pageNo: this.pageNum_,
        tmName: this.queryData.tradeMarkText, // 商标文字
        country: this.queryData.country.filter(i => i !== 'all').join(';') || 'CN',
        niceList: this.classifyStatus[this.classifyStatus.queryKey], //分类
        appno,
        regno,
        trademarkList,
        // status,
        lang: 'zh',
        istotal: this.queryData.istotal,
        isvalid: this.queryData.isvalid, // 是否有效
        queryWay: this.queryData.queryWay,
        applicant: this.queryData.tmApplicant, // 申请人名称
        address: this.queryData.tmAddress, // 申请人地址
        appBeginDate: this.queryData.appDateBegin, // 申请日//0606中文版添加
        appEndDate: this.queryData.appDateEnd, // 申请日//0606中文版添加
        trialBeginDate: this.queryData.trialDateBegin, //初审公告日
        trialEndDate: this.queryData.trialDateEnd, //初审公告日
        ggBeginDate: this.queryData.ggDateBegin, //注册公告日
        ggEndDate: this.queryData.ggDateEnd, //注册公告日
        nation: this.queryData.nation, // 0606中文版添加
        regBeginDate: this.queryData.regDateBegin, // 注册日
        regEndDate: this.queryData.regDateEnd, // 注册日
        priorityBeginDate: this.queryData.priorityDateBegin, // 优先权日
        priorityEndDate: this.queryData.priorityDateEnd, // 优先权日
        validBeginDate: this.queryData.privateDateBegin, // 专用权到期日
        validEndDate: this.queryData.privateDateEnd, // 专用权到期日
        agent: this.queryData.tmAgent, // 代理机构
        secondQuery: secondQuery, // 二次筛选所需参数
        isFirstQuery: this.queryData.isFirstQuery, // 是否为第一次查询（方便统计查询次数）
        productId: 27,
        highQueryConditions: this.queryData.highQueryConditions ? this.queryData.highQueryConditions : [], // 高级查询所选参数
        tmstatuscode: this.queryData.tmstatuscode, // 商标状态
    };
    for (let key in sendData) {
        if (sendData[key] == 'Invalid date') {
            sendData[key] = ''
        }
        if (sendData[key] === '' || sendData[key] === undefined) {
            delete sendData[key]
        }
    }
    let myDate = new Date();
    let s = myDate.getSeconds(); //秒

    //growingIO埋点
    let search_words = "";
    let search_type = "";
    if (this.othersItem.queryRecently == 'fastTextQuery' && this.queryData.queryMode == 0 && this.queryData.tradeMarkText) {
        search_words = this.queryData.tradeMarkText;
        search_type = '商标名称';
    }
    if (this.othersItem.queryRecently == 'fastTextQuery' && this.queryData.queryMode == 1 && this.queryData.applicationNumber) {
        search_words = this.queryData.applicationNumber;
        search_type = '申请号';
    }
    if (this.othersItem.queryRecently == 'fastTextQuery' && this.queryData.queryMode == 2 && this.queryData.tmApplicant) {
        search_words = this.queryData.tmApplicant;
        search_type = '申请人名称';
    }
    let search_method = this.othersItem.queryRecently.includes('f') ? '快速查询' : '组合查询';
    //let handle_area = this.othersItem.queryRecently.includes('f') ? this.queryData.whichLandCountry:this.choosedCountryItem.chName;
    let handle_area_arr = [];
    let handle_area = '';

    this.queryData.country.map(item => {
        let countryselected = this.queryData.countryList.find(function(country) {
            return country.countryKey == item;
        });
        if (countryselected) {
            handle_area_arr.push(countryselected.countryName);
        }
    });
    if (handle_area_arr && handle_area_arr.length > 0) {
        handle_area = handle_area_arr.join(';');
    }
    let entrance = this.$route.query.entranceval == 0 ? '侧边栏' : this.$route.query.entranceval == 1 ? '后台主页' : '组合查询按钮';
    this.queryTmInfoTextByMulCountry(sendData)
        .then((data) => {
            // let data = huaweiData;
            if (data.code == 400) {
                loading.close();
                Message(data.message);
                router.go(-1)
            } else if (data.data.status.code == 200) {
                loading.close();
                if (data.data.countryList) {
                    this.queryData.countryList.map(items => {
                        data.data.countryList.sort((a, b) => {
                            if (a.name > b.name) {
                                return 1
                            } else if (a.name < b.name) {
                                return -1
                            } else {
                                return 0
                            }
                        }).map(item => {
                            if (items.countryKey == item.name) {
                                item.countryName = items.countryName
                            }
                        })
                    })
                }
                if (data.data.tmstatuscode_list.length) {
                    data.data.tmstatuscode_list = data.data.tmstatuscode_list.map(item => {
                        return {
                            ...item,
                            statusName: statusObj[item.name]
                        }
                    })
                }
                this.responseDataFn(data.data)
                    //growingIO埋点
                let search_results = false;
                if (data.data.status.code == 200 && data.data.trademark_list.length) {
                    search_results = true;
                }
                gio('init', 'b8475611d1985bb7', {
                    'imp': false,
                    'hashtag': true
                });
                gio('track', 'trademarkSearchSuccess', {
                    'searchWords_var': search_words,
                    'searchIfResults_var': search_results,
                    'searchType_var': search_type,
                    'trademarkType_var': this.classifyStatus[this.classifyStatus.queryKey].split(';').length == 45 ? '全部类别' : this.classifyStatus[this.classifyStatus.queryKey],
                    'handleArea_var': handle_area,
                    'searchMethod_var': search_method,
                    'entrance_var': entrance
                });
                gio('send');
                // data.data.trademark_list.length = []
                if (data.data.trademark_list.length) {
                    this.othersItemFn({
                        isRequest: true
                    });
                    data.data.trademark_list.map(item => {
                        let tmdetail = "";
                        item.foreignService = '';
                        //商品服务数据处理
                        if (item.country == 'CN') {
                            item.foreignService = item.tmdetail;
                        } else if (item.country == 'US' || item.country == 'EU') {
                            try {
                                if (item.tmdetail_en.indexOf("niceCode\\\\\":") != -1) {
                                    item.tmdetail_en = item.tmdetail_en.replaceAll("niceCode\\\\\":", "niceCode\\\\\"");
                                }
                                tmdetail = JSON.parse(item.tmdetail_en || "[]");
                                item.foreignService = tmdetail.map(item2 => `${item2.niceCode}:${item2.value}`).join(";");
                            } catch (e) {
                                item.foreignService = item.tmdetail_en;
                            }
                        } else if (item.country == 'HK' || item.country == 'MO' || item.country == 'TW') {
                            tmdetail = JSON.parse(item.tmdetail || item.tmdetail_en || "[]");
                            tmdetail.map((item2) => {
                                for (let i in item2) {
                                    item.foreignService += i + ':' + item2[i] + ";";
                                }
                            });
                        } else {
                            tmdetail = JSON.parse(item.tmdetail_en || item.tmdetail || "[]");
                            tmdetail.map((item2) => {
                                for (let i in item2) {
                                    item.foreignService += i + ':' + item2[i] + ";";
                                }
                            });
                        }
                        //近似度显示处理
                        if (parseInt(item.score) >= 80) {
                            item.level = '高';
                        } else if (parseInt(item.score) >= 20 && parseInt(item.score) < 80) {
                            item.level = '中';
                        } else if (parseInt(item.score) >= 0 && parseInt(item.score) < 20) {
                            item.level = '低';
                        }
                        //申请人查询时，申请人集合
                        /*this.queryData.tmapplicantArr.push({
                            isQuery: false,
                            tmapplicant: item.tmapplicant
                        })*/
                    });
                    this.ajaxResponseFn({
                        queryTmInfo: data.data.trademark_list,
                        appnos: data.data.trademark_list.map((item) => item.appno),
                        ids: data.data.trademark_list.map((item) => item.id)
                    });
                    this.othersItemFn({
                        total: data.data.statistics.totalHits * 1,
                        total2: data.data.statistics.totalHits * 1
                    });
                    this.returnFn && this.returnFn();

                    this.queryItemsShowFn({
                        analyze: false,
                        condition: false,
                        result: true,
                        more: false,
                    })

                    let timeAll = '';
                    let myDate1 = new Date();
                    let s1 = myDate1.getSeconds(); //秒
                    if (s > s1) {
                        let timeSeconds = 60;
                        timeAll = (timeSeconds - s + s1) + '.00';
                    } else {
                        timeAll = s1 - s > 0 ? s1 - s + '.00' : '';
                    }
                    this.setUsedTime({
                        "actionCode": "queryTmInfoTextByMulCountry",
                        "usedTime": timeAll,
                    }).then((data) => {

                    }).catch((error) => {
                        console.log(error)
                    })
                } else {
                    // this.$message(this.$t('reg_query_lang.Empty_Data'));// 查询为空
                    this.othersItemFn({
                        total: data.data.statistics.totalHits * 1,
                        total2: data.data.statistics.totalHits * 1
                    });
                    this.ajaxResponseFn({
                        queryTmInfo: [],
                        appnos: [],
                        ids: [],
                    });
                    this.queryItemsShowFn({
                        noDataBol: true,
                        condition: false,
                        result: true,
                    });
                    this.returnFn && this.returnFn();
                }
            } else {
                this.ajaxResponseFn({
                    queryTmInfo: [],
                    appnos: [],
                    ids: []
                })
                loading.close();
            }
        })
        .catch((error) => {
            console.log(error);
            loading.close();
        })
};
/* 图片查询 */
export let queryTmInfoGraphByMulCountryFromDB = function(words) {
    let loading = Loading.service({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.3)',
        customClass: 'loading_interceptors'
    });
    this.queryItemsShowFn({
        noDataBol: false,
    });
    progressBarFn.bind(this)();
    new Promise((resolve, reject) => {
        // let arr = [];
        // if (this.queryData.viennaChoosedStr) {
        //     arr = this.queryData.viennaChoosedStr.split(";");
        // } else {
        //     if (this.viennaChoosedObj) {
        //         arr = this.viennaChoosedObj.filter(item => item.isQuery == false);
        //         arr = arr.map(item => item.zlbm);
        //     }
        // }
        let positionOne = {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        };
        let selected_areaOne = {
            xmax: 0,
            xmin: 0,
            ymax: 0,
            ymin: 0
        };
        let selected_area = {
            xmin: this.queryData.position.x,
            xmax: this.queryData.position.x + this.queryData.position.width,
            ymin: this.queryData.position.y,
            ymax: this.queryData.position.x + this.queryData.position.height
        };
        let status = "";
        if (this.queryData.statusList[this.queryData.country + '_list']) {
            this.queryData.statusList[this.queryData.country + '_list'].map((item) => {
                if (item.isQuery) {
                    status += item.index + ";";
                }
            });
        }
        let myDate = new Date();
        let s = myDate.getSeconds(); //秒

        let asideFormData = deepClone(this.$store.state.query.asideForm);
        for (let key in asideFormData) {
            let values = Object.values(asideFormData)
            if (values.some(i => (i || []).length)) {
                asideFormData[key] = asideFormData[key].join(';')
            } else {
                asideFormData = ''
            }
        }
        let secondQuery = asideFormData || '';

        // let sendCountry = deepClone(this.queryData.country)
        // sendCountry = sendCountry.length == 0 ? 'CN' : sendCountry.join(';')

        let sendData = {
            applicantQueryWay: this.queryData.applicantQueryWay,
            pageSize: this.pageSize_,
            pageNo: this.pageNum_,
            fileUrl: this.queryData.imgLineUrl,
            niceList: this.classifyStatus[this.classifyStatus.queryKey], //分类,
            // imageCodeList: arr.length ? arr : undefined,
            position: (Object.values(this.queryData.position)).reduce((prev, cur) => { prev + cur }) == 0 ? positionOne : this.queryData.position,
            selected_area: (Object.values(selected_area)).reduce((prev, cur) => { prev + cur }) == 0 ? selected_areaOne : selected_area,
            country: this.queryData.country.filter(i => i !== 'all').join(';') || 'CN',
            isvalid: this.queryData.isvalid, // 是否有效
            applicant: this.queryData.tmApplicant, // 申请人名称
            address: this.queryData.tmAddress, // 申请人地址
            appBeginDate: this.queryData.appDateBegin == 'Invalid date' ? '' : this.queryData.appDateBegin, // 申请日//0606中文版添加
            appEndDate: this.queryData.appDateEnd == 'Invalid date' ? '' : this.queryData.appDateEnd, // 申请日//0606中文版添加
            trialBeginDate: this.queryData.trialDateBegin == 'Invalid date' ? '' : this.queryData.trialDateBegin, //初审公告日
            trialEndDate: this.queryData.trialDateEnd == 'Invalid date' ? '' : this.queryData.trialDateEnd, //初审公告日
            ggBeginDate: this.queryData.ggDateBegin == 'Invalid date' ? '' : this.queryData.ggDateBegin, //注册公告日
            ggEndDate: this.queryData.ggDateEnd == 'Invalid date' ? '' : this.queryData.ggDateEnd, //注册公告日
            nation: this.queryData.nation, // 0606中文版添加
            regBeginDate: this.queryData.regDateBegin == 'Invalid date' ? '' : this.queryData.regDateBegin, // 注册日
            regEndDate: this.queryData.regDateEnd == 'Invalid date' ? '' : this.queryData.regDateEnd, // 注册日
            priorityBeginDate: this.queryData.priorityDateBegin == 'Invalid date' ? '' : this.queryData.priorityDateBegin, // 优先权日
            priorityEndDate: this.queryData.priorityDateEnd == 'Invalid date' ? '' : this.queryData.priorityDateEnd, // 优先权日
            validBeginDate: this.queryData.privateDateBegin == 'Invalid date' ? '' : this.queryData.privateDateBegin, // 专用权到期日
            validEndDate: this.queryData.privateDateEnd == 'Invalid date' ? '' : this.queryData.privateDateEnd, // 专用权到期日
            agent: this.queryData.tmAgent, // 代理机构
            istotal: this.queryData.istotal,
            // status,
            lang: 'zh',
            secondQuery: secondQuery, // 二次筛选所需参数
            isFirstQuery: this.queryData.isFirstQuery, // 是否为第一次查询（方便统计查询次数）
            productId: 27,
            highQueryConditions: this.queryData.highQueryConditions ? this.queryData.highQueryConditions : [], // 高级查询所选参数
            tmstatuscode: this.queryData.tmstatuscode, // 商标状态
        }
        for (let key in sendData) {
            if (sendData[key] == 'Invalid date') {
                sendData[key] = ''
            }
            if (sendData[key] === '' || sendData[key] === undefined) {
                delete sendData[key]
            }
        }
        //growingIO埋点
        let search_method = this.othersItem.queryRecently.includes('f') ? '快速查询' : '组合查询';
        let handle_area_arr = [];
        let handle_area = '';

        this.queryData.country.map(item => {
            let countryselected = this.queryData.countryList.find(function(country) {
                return country.countryKey == item;
            });
            if (countryselected) {
                handle_area_arr.push(countryselected.countryName);
            }
        });
        if (handle_area_arr && handle_area_arr.length > 0) {
            handle_area = handle_area_arr.join(';');
        }
        let entrance = this.$route.query.entranceval == 0 ? '侧边栏' : this.$route.query.entranceval == 1 ? '后台主页' : '组合查询按钮';
        this.queryTmInfoGraphByMulCountry(sendData)
            .then((data) => {
                if (data.code == 400) {
                    loading.close()
                    Message(data.message)
                    router.go(-1)
                } else if (data.data.status.code == 200) {
                    loading.close()
                    if (data.data.countryList) {
                        this.queryData.countryList.map(items => {
                            data.data.countryList.sort((a, b) => {
                                if (a.name > b.name) {
                                    return 1
                                } else if (a.name < b.name) {
                                    return -1
                                } else {
                                    return 0
                                }
                            }).map(item => {
                                if (items.countryKey == item.name) {
                                    item.countryName = items.countryName
                                }
                            })
                        })
                    }
                    if (data.data.tmstatuscode_list.length) {
                        data.data.tmstatuscode_list = data.data.tmstatuscode_list.map(item => {
                            return {
                                ...item,
                                statusName: statusObj[item.name]
                            }
                        })
                    }
                    this.responseDataFn(data.data)
                        //growingIO埋点
                    let search_results = false;
                    if (data.code == 200 && data.data && data.data.trademark_list.length) {
                        search_results = true;
                    }
                    gio('init', 'b8475611d1985bb7', {
                        'imp': false,
                        'hashtag': true
                    });
                    gio('track', 'trademarkSearchSuccess', {
                        'searchWords_var': '图片查询',
                        'searchIfResults_var': search_results,
                        'searchType_var': '近似商标',
                        'trademarkType_var': this.classifyStatus[this.classifyStatus.queryKey].split(';').length == 45 ? '全部类别' : this.classifyStatus[this.classifyStatus.queryKey],
                        'handleArea_var': handle_area,
                        'searchMethod_var': search_method,
                        'entrance_var': entrance
                    });
                    gio('send');
                    if (data.data.trademark_list.length) {
                        if (typeof(data.data.pred_label) === 'string') {
                            data.data.pred_label = JSON.parse(data.data.pred_label)
                        }
                        if (!this.queryData.viennaChoosedArr.length) {
                            this.queryDataFn({
                                viennaChoosedArr: data.data.pred_label,
                                viennaFromAI: data.data.pred_label
                            })
                        }
                        this.othersItemFn({
                            isRequest: true
                        });
                        data.data.trademark_list.map(item => {
                            let tmdetail = "";
                            item.foreignService = '';
                            //商品服务数据处理
                            if (item.country == 'CN') {
                                item.foreignService = item.tmdetail;
                            } else if (item.country == 'US' || item.country == 'EU') {
                                try {
                                    if (item.tmdetail_en.indexOf("niceCode\\\\\":") != -1) {
                                        item.tmdetail_en = item.tmdetail_en.replaceAll("niceCode\\\\\":", "niceCode\\\\\"");
                                    }
                                    tmdetail = JSON.parse(item.tmdetail_en || "[]");
                                    item.foreignService = tmdetail.map(item2 => `${item2.niceCode}:${item2.value}`).join(";");
                                } catch (e) {
                                    item.foreignService = item.tmdetail_en;
                                }

                            } else {
                                tmdetail = JSON.parse(item.tmdetail_en || item.tmdetail || "[]");
                                tmdetail.map((item2) => {
                                    for (let i in item2) {
                                        item.foreignService += i + ':' + item2[i] + ";";
                                    }
                                });
                            }
                            if (parseInt(item.score) <= 85) {
                                item.level = '高';
                            } else if (parseInt(item.score) >= 85 && parseInt(item.score) < 250) {
                                item.level = '中';
                            } else if (parseInt(item.score) >= 250) {
                                item.level = '低';
                            }
                        });
                        this.ajaxResponseFn({
                            queryTmInfo: data.data.trademark_list,
                            appnos: data.data.trademark_list.map((item) => item.appno),
                            ids: data.data.trademark_list.map((item) => item.id)
                        });
                        this.othersItemFn({
                            total: data.data.statistics.totalHits * 1,
                            total2: data.data.statistics.totalHits * 1
                        });
                        this.returnFn && this.returnFn();
                        this.queryItemsShowFn({
                            analyze: false,
                            condition: false,
                            result: true,
                            more: false,
                        })
                        let timeAll = '';
                        let myDate1 = new Date();
                        let s1 = myDate1.getSeconds(); //秒
                        if (s > s1) {
                            let timeSeconds = 60;
                            timeAll = (timeSeconds - s + s1) + '.00';
                        } else {
                            timeAll = s1 - s > 0 ? s1 - s + '.00' : '';
                        }
                        this.setUsedTime({
                            "actionCode": "queryTmInfoGraphByMulCountry",
                            "usedTime": timeAll,
                        }).then((data) => {

                        }).catch((error) => {
                            console.log(error)
                        })
                    } else {
                        // this.$message(this.$t('reg_query_lang.Empty_Data'));// 查询为空
                        this.othersItemFn({
                            total: data.data.statistics.totalHits * 1,
                            total2: data.data.statistics.totalHits * 1
                        });
                        this.ajaxResponseFn({
                            queryTmInfo: [],
                            regNos: [],
                            ids: [],
                        });
                        this.queryItemsShowFn({
                            noDataBol: true,
                            condition: false,
                            result: true,
                        });
                        this.returnFn && this.returnFn();
                    }
                } else {
                    loading.close();
                    this.ajaxResponseFn({
                        queryTmInfo: [],
                        regNos: [],
                        ids: []
                    })
                }
            })
            .catch((data) => {
                loading.close();
                this.queryItemsShowFn({
                    analyze: false,
                    condition: false,
                    result: false,
                    more: false,
                });
                this.ajaxResponseFn({
                    queryTmInfo: [],
                    appnos: [],
                    ids: []
                });
                reject(data)
            })
    })
};
