<template>
  <div class="viennaCodezu">
    <div class="top" v-if="false">
      <i class="el-icon-warning"></i>
      <span>{{$t('follow_up_FE_lang.Please_note2')}}</span>
    </div>
    <div class="middle vienna-style">
      <el-checkbox-group v-model="queryData.viennaChoosedArr">
        <ul class="first-ul" v-if="true">
          <li class="first-li" v-for="(item,index) in noReset.vienna">
            <p class="first-p" @click="isOpenFn(item)"><span
              v-if="$i18n.locale=='zh-CN'">{{item.fldm+" "+item.flname}}</span><span
              v-if="$i18n.locale=='en-US'">{{item.fldm+" "+item.flnameEn}}</span>
            </p>
            <ul class="second-ul" v-if="item.isOpen">
              <li class="second-li" v-for="(item2,index2) in item.children">
                <p class="second-p" @click="isOpenFn(item2)">
                  <el-checkbox @change="viennaChange" :max="5" :label="item2.zlbm">
                    <span v-if="$i18n.locale=='zh-CN'">{{item2.zlbm+" "+item2.zlname}}</span>
                    <span v-if="$i18n.locale=='en-US'">{{item2.zlbm+" "+item2.zlnameEn}}</span>
                  </el-checkbox>
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </el-checkbox-group>
    </div>
    <div class="bottom">
      <el-button class="viennaConfirm el-long-defalut-button" @click="viennaClose">
        {{$t('follow_up_FE_lang.confirm')}}
      </el-button>
      <el-button class="viennaClose el-long-white-button" @click="viennaConfirm">{{$t('follow_up_FE_lang.close')}}
      </el-button>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';

  export default {
    name: "viennaCodezu",
    data() {
      return {
        // viennaChoosedArr: [],//选中的图形像素数组
        // vienna: [],//接口获取到整个维也纳分类,,区别于老版本的维也纳
        // viennaThirdText: [],//包含所有的维也纳的最后一级
        //viennaChoosedStr:"",选中的图形像素,见computed
      }
    },
    components: {},
    created() {
    },
    mounted() {
      // this.getViennaAllFromDB();
    },
    methods: {
      ...mapMutations('query', ['queryItemsShowFn', 'queryDataFn', 'ajaxResponseFn', 'othersItemFn', 'pageNum_Fn', 'pageSize_Fn', 'noResetFn', 'queryBtnClickNumFn']),
      isOpenFn(item) {
        item.isOpen = !item.isOpen
      },
      viennaChange() {

      },
      viennaConfirm() {
        this.$emit("closeViennaFn","confirm");
      },
      viennaClose() {
        this.$emit("closeViennaFn");
      },
      updateVienna() {
        this.noReset.vienna.map((item) => {
          item.isOpen = false;
          item.children.map((item2) => {
            item2.isOpen = false;
            if (this.queryData.viennaChoosedArr.includes(item2.zlbm)) {//0716,因为ai只支持2级,所以高级查询也统一修改为支持2级;
              item.isOpen = item2.isOpen= true;
            }
     /*      //0716,因为ai只支持2级,所以高级查询也统一修改为支持2级;
			 item2.children && item2.children.map((item3) => {
              item3.isOpen = false;
              if (this.queryData.viennaChoosedArr.includes(item3.jlbm)) {
                item.isOpen = item2.isOpen = item3.isOpen = true;
              }
            })*/
          })
        });
      },
    },
    computed: {
      ...mapState('query', ['queryItemsShow', 'queryData', 'ajaxResponse', 'othersItem', 'pageNum_', 'pageSize_', 'noReset', 'queryBtnClickNum']),
    },
  }
</script>

<style lang="less">
  .viennaCodezu {
    padding-top: 30px;
    width: 950px;
    border-radius: 30px;
    text-align: left;
    .top {
      height: 53px;
      line-height: 53px;
      font-size: 14px;
      letter-spacing: 1px;
      color: #666666;
      padding: 0px 50px;
      border-bottom: 1px solid #cecece;
      background-color: #efefef;
      border-radius: 3px;
      i {
        color: #e96364;
      }
      span {
        margin-left: 12px;
      }
    }
    /*维也纳分类*/
    .middle.vienna-style {
      height: 433px;
      overflow: auto;
      padding-left: 15px;
      .first-ul {
        /*background: red;*/
        font-size: 13px;
        .first-li {
          /*text-indent: 50px;*/
          padding: 0 18px;
          &:hover {
            background: #f7f7f7;
          }
          .first-p {
            cursor: pointer;
            min-height: 40px;
            line-height: 40px;
            font-size: 14px;
            letter-spacing: 0px;
            color: #333333;
            & > span {
              /*white-space: nowrap;*/
              /*
                */
            }
          }
          .second-ul {
            /*background: orange;*/
            .second-li {
              padding: 0 18px;
              &:nth-last-of-type(1) {
                padding-bottom: 0;
              }
              .second-p {
                cursor: pointer;
                padding: 6px;
                line-height: 18px;
                & > span {
                  /*white-space: nowrap;*/
                  /*
                   */
                }
                &:hover {
                  background: #f7f7f7;
                }
              }
              .third-ul {
                .third-li {
                  padding: 0;
                  /*text-indent: 50+18+18px;*/
                  padding: 0 18px;
                  .third-p {
                    min-height: 30px;
                    line-height: 30px;
                    .el-checkbox {
                      text-indent: 0px;
                      //复选框样式见long.scss
                      .el-checkbox__label {
                        & > span {
                          /*background: blue;*/
                        }
                      }
                    }

                  }
                }
              }
            }
          }
        }
      }
      label.el-checkbox {
        white-space: normal;
        & > * {
          display: inline-block;
          vertical-align: top;
        }
        span.el-checkbox__input {
          width: 14px;
        }
        span.el-checkbox__label {
          width: calc(100% - 14px);
        }
      }
    }
    .bottom {
      height: 94px;
      padding: 30px 54px;
      text-align: right;
      .el-button {
        height: 30px;
        width: 100px;
      }
      .viennaConfirm {

      }
      .viennaClose {
        margin-left: 22px;
      }
    }
  }
</style>
