<template>
  <div class="progress" v-show="dialogVisible">
<!--    <el-dialog :visible.sync="dialogVisible" width="30%"  center class="Note progressLoading">-->
      <!--    <div class="progressResult" v-if="translatinging == 1">-->
      <div class="progressResult">
        <div class="loading-outer">
          <p class="loading-inner" :style="{ width: progressResult + '%' }"></p>
        </div>
        <p class="text">{{progressResult + '%'}}</p>
      </div>
<!--    </el-dialog>-->
  </div>
</template>
<script>
	export default {
		data() {
			return {
        filePross:'',//图片上传是否成功
        progressResult:'',//进度条值
        translatinging: '',//是否显示进度条
        sonMsg:'子组件',
        user:'子传父的内容',
        dialogVisible:false,
			}
		},
    props:['psMsg'],
		components: {
		},
		mounted() {

		},
		created() {
		},
		methods: {
      etUploadProgressFn () {
          this.translatinging = 1;
          this.dialogVisible = true;
          this.getUploadProgress({})
            .then(res => {
              this.progressResult = Number(res.data.progress);
              if (this.psMsg!='') {
                this.progressResult = Number(100);
                this.translatinging = '';
                setTimeout(() => {
                  this.dialogVisible = false;
                }, 100);
              } else {
                this.dialogVisible = true;
                setTimeout(() => {
                  this.etUploadProgressFn();
                }, 800);
              }
            })
            .catch(error => {
              console.log(error);
            });
      }
		},
		computed: {
    },

	}
</script>

<style lang="less">
  .progress{
    background: rgba(241, 241, 241, 0.9) !important;
    position: fixed;  /*相对于浏览器窗口进行定位*/
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 2008;
  .progressResult {
    height: 30px;
    width: 265px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 160px;
    right: 0;
    margin: auto;
    z-index:9999;
    .loading-outer {
      width: 100%;
      height: 6px;
      background-color: #d6d6d6;
      border-radius: 4px;
      .loading-inner {
        width: 140px;
        height: 6px;
        background-color: #eb5657;
        border-radius: 4px;
      }
    }
    .text {
      text-align: center;
      font-size: 14px;
      letter-spacing: 0px;
      color: #eb5657;
      margin-top: 12px;
    }
  }
  }
</style>
